<template>
  <div>
    <h1>{{ $t('testapp.createlicense.title') }}</h1>
    <h2>{{ $t('testapp.createlicense.introduction.title') }}</h2>
    <p>{{ $t('testapp.createlicense.introduction.text') }}</p>
    <div class="embed-responsive embed-responsive-16by9" style="max-width: 560px; margin: 0 auto;">
      <iframe src="https://www.youtube.com/embed/pPhqhQkcPVk" class="embed-responsive-item" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <h2>{{ $t('testapp.createlicense.createaccount.title') }}</h2>
    <p v-html="$t('testapp.createlicense.createaccount.text')"></p>
    <h2>{{ $t('testapp.createlicense.createorganization.title') }}</h2>
    <p v-html="$t('testapp.createlicense.createorganization.text')"></p>
    <h2>{{ $t('testapp.createlicense.createapp.title') }}</h2>
    <p v-html="$t('testapp.createlicense.createapp.text')"></p>
    <b-row class="mb-4">
      <b-col lg="2" md="3" sm="4">
        <label for="app-name-input" class="mt-sm-2 mr-sm-2">{{ $t('testapp.createlicense.createapp.inputlabel') }}</label>
      </b-col>
      <b-col md="4" sm="8">
        <b-form-input
          v-model="apiCredentials.appName"
          id="app-name-input"
          :placeholder="$t('testapp.createlicense.createapp.inputplaceholder')"
          :state="!!apiCredentials.appName">
        </b-form-input>
      </b-col>
    </b-row>
    <p v-html="$t('testapp.createlicense.createapp.textredirect', { OAuthRedirectURI: redirectURI })"></p>
    <h2>{{ $t('testapp.createlicense.credentials.title') }}</h2>
    <p v-html="$t('testapp.createlicense.credentials.text')"></p>
    <b-row class="mb-4">
      <b-col lg="2" md="3" sm="4">
        <label for="app-client-id-input" class="mt-sm-2 mr-sm-2">{{ $t('testapp.createlicense.credentials.inputclientidlabel') }}</label>
      </b-col>
      <b-col md="4" sm="8">
        <b-form-input
          v-model="apiCredentials.clientID"
          id="app-client-id-input"
          :placeholder="$t('testapp.createlicense.credentials.inputclientidplaceholder')"
          :disabled="!apiCredentials.appName"
          :state="!!apiCredentials.clientID"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col lg="2" md="3" sm="4">
        <label for="app-client-secret-input" class="mt-sm-2 mr-sm-2">{{ $t('testapp.createlicense.credentials.inputclientsecretlabel') }}</label>
      </b-col>
      <b-col md="4" sm="8">
        <b-form-input
          v-model="apiCredentials.clientSecret"
          id="app-client-secret-input"
          :placeholder="$t('testapp.createlicense.credentials.inputclientsecretplaceholder')"
          :disabled="!apiCredentials.appName"
          :state="!!apiCredentials.clientSecret"></b-form-input>
      </b-col>
    </b-row>
    <h2>{{ $t('testapp.createlicense.redirectsso.title') }}</h2>
    <p v-html="$t('testapp.createlicense.redirectsso.text')"></p>
    <b-button variant="info"
      :disabled="!this.apiCredentials.clientID || !this.apiCredentials.clientSecret || !this.apiCredentials.appName"
      @click="$emit('redirect-to-sso')">
      {{ $t('testapp.createlicense.redirectsso.buttonredirect') }}
    </b-button>
  </div>
</template>

<script>
export default {
  props: [
    'apiCredentials', 'apiAccessToken', 'loginURI', 'redirectURI'
  ],
  created () {
    this.$emit('reset-test-app')
  }
}
</script>
