<template>
  <div>
    <h1>{{ $t('testapp.introduction.title') }}</h1>
    <h2>{{ $t('testapp.introduction.introduction.title') }}</h2>
    <p v-html="$t('testapp.introduction.introduction.text')"></p>
    <h2>{{ $t('testapp.introduction.letsgo.title') }}</h2>
    <p v-html="$t('testapp.introduction.letsgo.text')"></p>
    <div class="d-md-flex justify-content-center">
      <div class="px-4">
        <b-button :to="{ name: 'testapp-createaccount'}" variant="success">{{ $t('testapp.introduction.button.wizard') }}</b-button>
      </div>
      <div class="px-4">
        <b-button :to="{ name: 'testapp-sendlicense'}">{{ $t('testapp.introduction.button.advanced') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    this.$emit('reset-test-app')
  }
}
</script>

<style scoped>
  .btn {
    margin-top: 25px;
  }
</style>
