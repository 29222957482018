<template>
  <div>
    <h1>Receive Authorization code</h1>
    <p>{{ comment }}</p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      comment: 'Something wrong happened.'
    }
  },
  created () {
    if (this.$route.query.error) {
      this.comment = 'The authentication server returned an error: ' + this.route.query.error
      return null
    }
    if (!this.$route.query.code) {
      this.comment = 'No authentication code has been submitted.'
      return null
    }
    this.$emit('return-from-redirect', this.$route.query.code)
  }
}
</script>
