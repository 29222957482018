<template>
  <div>
    <h1>{{ $t('testapp.managelicense.title') }}</h1>
    <b-alert :show="!!getTokenError" variant="danger">{{ $t('testapp.managelicense.alertgettoken', { message: getTokenError }) }}</b-alert>
    <b-row>
      <b-col lg="8">
        <h2>{{ $t('testapp.managelicense.currentstatus.title') }}</h2>
        <p>{{ $t('testapp.managelicense.currentstatus.text') }}</p>
        <dl class="row">
          <dt class="col-lg-4">{{ $t('testapp.managelicense.currentstatus.appnamelabel') }}</dt>
          <dd class="col-lg-8">{{ apiCredentials.appName }}&nbsp;</dd>
          <dt class="col-lg-4">{{ $t('testapp.managelicense.currentstatus.clientidlabel') }}</dt>
          <dd class="col-lg-8">{{ apiCredentials.clientID }}&nbsp;</dd>
          <dt class="col-lg-4">{{ $t('testapp.managelicense.currentstatus.accesstokenlabel') }}</dt>
          <dd v-if="requesting" class="col-lg-8">Please wait, requesting...&nbsp;</dd>
          <dd v-else class="col-lg-8">{{ apiAccessToken.accessToken }}&nbsp;</dd>
          <dt class="col-lg-4">{{ $t('testapp.managelicense.currentstatus.expirelabel') }}</dt>
          <dd v-if="requesting" class="col-lg-8">Please wait, requesting...&nbsp;</dd>
          <dd v-else class="col-lg-8"><span :class="{ 'mark': expireIsOver }">{{ expiresInString }}</span>&nbsp;</dd>
          <dt class="col-lg-4">{{ $t('testapp.managelicense.currentstatus.refreshtokenlabel') }}</dt>
          <dd v-if="requesting" class="col-lg-8">Please wait, requesting...&nbsp;</dd>
          <dd v-else class="col-lg-8">{{ apiAccessToken.refreshToken }}&nbsp;</dd>
        </dl>
      </b-col>
      <b-col lg="4">
        <h2>{{ $t('testapp.managelicense.tokenmanagement.title') }}</h2>
        <b-alert :show="expireIsOver" variant="warning">{{ $t('testapp.managelicense.tokenmanagement.alertrefresh') }}</b-alert>
        <p>{{ $t('testapp.managelicense.tokenmanagement.textrefresh') }}</p>
        <b-button @click="$emit('refresh-token')" variant="primary" :disabled="!apiAccessToken.refreshToken">{{ $t('testapp.managelicense.tokenmanagement.actionrefresh') }}</b-button>
        <b-alert :show="!apiAccessToken.accessToken" variant="warning">{{ $t('testapp.managelicense.tokenmanagement.alertnew') }}</b-alert>
        <p>{{ $t('testapp.managelicense.tokenmanagement.textnew') }}</p>
        <b-button @click="$emit('redirect-to-sso')" variant="secondary" :disabled="!apiCredentials.clientID">{{ $t('testapp.managelicense.tokenmanagement.actionnew') }}</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <h2>{{ $t('testapp.managelicense.downloadlicense.title') }}</h2>
        <b-alert :show="configUpdated" variant="warning" fade>{{ $t('testapp.managelicense.downloadlicense.alert') }}</b-alert>
        <p>{{ $t('testapp.managelicense.downloadlicense.text') }}</p>
        <b-button :href="licenseFileContent" download="dgk-api.license" variant="success">{{ $t('testapp.managelicense.downloadlicense.action') }}</b-button>
      </b-col>
      <b-col lg="6">
        <h2>{{ $t('testapp.managelicense.excelfile.title') }}</h2>
        <p>{{ $t('testapp.managelicense.excelfile.text') }}</p>
        <b-button variant="success" href="excel/dgk-api.xlsm">{{ $t('testapp.managelicense.excelfile.action') }}</b-button>
      </b-col>
    </b-row>
    <h2>{{ $t('testapp.managelicense.faq.title') }}</h2>
    <p v-html="$t('testapp.managelicense.faq.text')"></p>
    <div role="tablist">
      <b-card no-body class="mb-1" v-for="(faq, index) in $t('testapp.managelicense.faq.list')" :key="index">
        <b-card-header header-tag="header" role="tab" v-b-toggle="'faq-' + index">
          {{ faq.question }}
        </b-card-header>
        <b-collapse :id="'faq-' + index" accordion="faq-accordion" role="tabpanel">
          <b-card-body>
            <p class="card-text" v-html="faq.answer"></p>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'userInfo',
    'apiCredentials',
    'apiAccessToken',
    'configUpdated',
    'getTokenError',
    'requesting'
  ],
  created () {
    if (!this.apiCredentials.clientID) {
      this.$router.push({ name: 'testapp-introduction' })
    }
  },
  computed: {
    expiresInString () {
      if (!this.apiAccessToken.expiresIn) {
        return ''
      }
      const expireDate = new Date(this.apiAccessToken.expiresIn)
      return expireDate.toLocaleString('en-US')
    },
    expireIsOver () {
      if (!this.apiAccessToken.expiresIn) {
        return false
      }
      const expireDate = new Date(this.apiAccessToken.expiresIn)
      const currentDate = new Date()
      return expireDate < currentDate
    },
    licenseFileContent () {
      const content = JSON.stringify({
        userInfo: this.userInfo,
        apiCredentials: this.apiCredentials,
        apiAccessToken: this.apiAccessToken
      })
      const blob = new Blob([content], { type: 'application/json;charset=utf-8' })
      return window.URL.createObjectURL(blob)
    }
  }
}
</script>

<style scoped>
  .mark {
    background-color: red;
    color: white;
  }
</style>
