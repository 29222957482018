<template>
  <div>
    <h1>Authorisation code</h1>
    <p>{{ comment }}</p>
    <template v-if="apiAccessToken.authenticationCode">
      <p>
        Your authorisation code is <code style="font-weight: bold; font-size: 16px; word-break: break-all;">{{ apiAccessToken.authenticationCode }}</code>.<br/>
        Copy/Past your authorisation code to your Excel file before it expires in <strong>{{ remainingSeconds.toFixed(0) }} seconds.</strong>
      </p>
      <b-button
        v-show="canCopyToClipboard"
        size="sm"
        @click="updateClipboard(apiAccessToken.authenticationCode)"
        variant="info">
        Copy to clipboard</b-button>
    </template>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      comment: 'Something wrong happened.',
      remainingSeconds: 60,
      canCopyToClipboard: false
    }
  },
  props: [
    'apiAccessToken'
  ],
  created () {
    try {
      navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
        if (result.state === 'granted' || result.state === 'prompt') {
          this.canCopyToClipboard = true
        }
      })
    } catch (error) {
      this.canCopyToClipboard = false
    }

    if (this.apiAccessToken.authenticationCode) {
      this.comment = ''
      const counter = setInterval(() => {
        this.remainingSeconds -= 0.1
        if (this.remainingSeconds < 0) {
          clearInterval(counter)
          this.$router.push({ name: 'testapp-introduction' })
        }
      }, 100)
    }
  },
  methods: {
    updateClipboard (text) {
      navigator.clipboard.writeText(text).then(() => {}, () => { alert('Failed to copy to clipboard. Please copy it manualy') })
    }
  }
}
</script>
