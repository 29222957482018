<template>
  <div>
    <router-view
      :user-info="userInfo"
      :api-credentials="apiCredentials"
      :api-access-token="apiAccessToken"
      :redirect-u-r-i="redirectURI()"
      :config-updated="configUpdated"
      :get-token-error="getTokenError"
      :requesting="requesting"
      @save-data="saveData"
      @load-data="loadData"
      @return-from-redirect="returnFromRedirect"
      @refresh-token="getRefreshToken"
      @redirect-to-sso="redirectToSSO"
      @reset-test-app="resetTestApp"
    />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      userInfo: {
        firstname: null,
        lastname: null,
        company: null
      },
      apiCredentials: {
        appName: null,
        clientID: null,
        clientSecret: null,
        redirectURI: null
      },
      apiAccessToken: {
        authenticationCode: null,
        accessToken: null,
        refreshToken: null,
        expiresIn: null,
        excelTimestamp: null
      },
      configUpdated: false,
      getTokenError: null,
      requesting: false
    }
  },
  methods: {
    saveData () {
      localStorage.setItem('apiCredentials', JSON.stringify(this.apiCredentials))
      localStorage.setItem('apiAccessToken', JSON.stringify(this.apiAccessToken))
      localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
    },
    loadData () {
      if (localStorage.getItem('apiCredentials') && localStorage.getItem('apiAccessToken') && localStorage.getItem('userInfo')) {
        this.apiCredentials = JSON.parse(localStorage.getItem('apiCredentials'))
        this.apiAccessToken = JSON.parse(localStorage.getItem('apiAccessToken'))
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.configUpdated = true
        this.clearData()
        return true
      }
      return false
    },
    clearData () {
      localStorage.clear('apiCredentials')
      localStorage.clear('apiAccessToken')
      localStorage.clear('userInfo')
    },
    getAccessToken () {
      const params = new URLSearchParams()
      params.append('code', this.apiAccessToken.authenticationCode)
      params.append('client_id', this.apiCredentials.clientID)
      params.append('client_secret', this.apiCredentials.clientSecret)
      params.append('redirect_uri', this.apiCredentials.redirectURI)
      params.append('grant_type', 'authorization_code')

      this.getToken(params)
    },
    getRefreshToken () {
      const params = new URLSearchParams()
      params.append('refresh_token', this.apiAccessToken.refreshToken)
      params.append('client_id', this.apiCredentials.clientID)
      params.append('client_secret', this.apiCredentials.clientSecret)
      params.append('grant_type', 'refresh_token')

      this.getToken(params)
    },
    getToken (params) {
      const ssoURI = 'proxy/proxytoken.php' // Use a backend proxy to submit the request (CORS issue with the SSO server)
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      const vm = this
      const axiosInterceptors = axios.create({ timeout: 10000 })
      this.getTokenError = null
      this.requesting = true

      axiosInterceptors
        .post(ssoURI, params, { headers })
        .then(response => {
          if (response.data.error) {
            vm.getTokenError = response.data.error_description
            vm.configUpdated = false
            return
          }
          vm.apiAccessToken.accessToken = response.data.access_token
          vm.apiAccessToken.refreshToken = response.data.refresh_token
          var expireDateTime = new Date()
          expireDateTime.setSeconds(expireDateTime.getSeconds() + response.data.expires_in)
          vm.apiAccessToken.expiresIn = expireDateTime
          vm.apiAccessToken.excelTimestamp = Math.floor((expireDateTime.getTime() - expireDateTime.getTimezoneOffset() * 60000) / 1000)
          vm.configUpdated = true
          vm.requesting = false
        })
        .catch(error => {
          vm.getTokenError = error.message
          vm.configUpdated = false
          vm.requesting = false
        })
    },
    returnFromRedirect (code) {
      if (this.loadData()) {
        this.apiAccessToken.authenticationCode = code
        this.getAccessToken()
        this.$router.push({ name: 'testapp-managelicense' })
      } else {
        this.apiAccessToken.authenticationCode = code
        this.$router.push({ name: 'testapp-exposecode' })
      }
    },
    redirectURI () {
      return window.location.protocol +
        '//' +
        window.location.hostname + // replace by "window.location.host" if you want the port as well
        window.location.pathname +
        this.$router.resolve({ name: 'testapp-receiveredirect' }).href.substr(2)
    },
    loginURI () {
      return 'https://api.digikey.com/v1/oauth2/authorize?response_type=code&client_id=' +
        this.apiCredentials.clientID +
        '&redirect_uri=' +
        encodeURI(this.apiCredentials.redirectURI)
    },
    redirectToSSO () {
      this.apiCredentials.redirectURI = this.redirectURI()
      this.saveData()
      window.location.replace(this.loginURI())
    },
    resetTestApp () {
      this.userInfo = {
        firstname: null,
        lastname: null,
        company: null
      }
      this.apiCredentials = {
        appName: null,
        clientID: null,
        clientSecret: null,
        redirectURI: null
      }
      this.apiAccessToken = {
        authenticationCode: null,
        accessToken: null,
        refreshToken: null,
        expiresIn: null,
        excelTimestamp: null
      }
      this.configUpdated = false
      this.getTokenError = null
      this.requesting = false

      this.clearData()
    }
  }
}
</script>

<style scoped>
.teaserapp-enter-active {
  animation: bounce-up .8s;
}
.teaserapp-leave-active {
  animation: bounce-up .25s reverse;
}
@keyframes bounce-up {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
</style>
