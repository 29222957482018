<template>
  <div>
    <transition name="alertbadfile">
      <b-alert :show="badFile" variant="danger">{{ $t('testapp.uploadlicense.badfile') }}</b-alert>
    </transition>
    <h1>{{ $t('testapp.uploadlicense.title') }}</h1>
    <p>{{ $t('testapp.uploadlicense.text') }}</p>
      <b-form-file  @change="newFileSend"></b-form-file>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      badFile: false
    }
  },
  props: [
    'apiCredentials', 'apiAccessToken', 'userInfo'
  ],
  created () {
    this.$emit('reset-test-app')
  },
  methods: {
    newFileSend (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      var reader = new FileReader()
      reader.onload = (e) => {
        try {
          const licenseFileJson = JSON.parse(e.target.result)
          if (typeof licenseFileJson.userInfo === 'object') {
            this.userInfo.firstname = licenseFileJson.userInfo.firstname
            this.userInfo.lastname = licenseFileJson.userInfo.lastname
            this.userInfo.company = licenseFileJson.userInfo.company
          }
          if (typeof licenseFileJson.apiCredentials === 'object') {
            this.apiCredentials.appName = licenseFileJson.apiCredentials.appName
            this.apiCredentials.clientID = licenseFileJson.apiCredentials.clientID
            this.apiCredentials.clientSecret = licenseFileJson.apiCredentials.clientSecret
            this.apiCredentials.redirectURI = licenseFileJson.apiCredentials.redirectURI
          }
          if (typeof licenseFileJson.apiAccessToken === 'object') {
            this.apiAccessToken.authenticationCode = licenseFileJson.apiAccessToken.authenticationCode
            this.apiAccessToken.accessToken = licenseFileJson.apiAccessToken.accessToken
            this.apiAccessToken.refreshToken = licenseFileJson.apiAccessToken.refreshToken
            this.apiAccessToken.expiresIn = licenseFileJson.apiAccessToken.expiresIn
            this.apiAccessToken.excelTimestamp = licenseFileJson.apiAccessToken.excelTimestamp
          }
          this.$router.push({ name: 'testapp-managelicense' })
        } catch (error) {
          this.badFile = true
        }
      }
      reader.readAsText(files.item(0))
    }
  }
}
</script>

<style scoped>
.alertbadfile-enter-active {
  transition: opacity 1s;
}
.alertbadfile-enter {
  opacity: 0;
}
</style>
